import axios from 'axios'

class ExpensesApi {
  async get({ page, limit, isDeleted, search, start_date, end_date }) {
    axios.defaults.headers.common.Authorization = localStorage.getItem('accessToken')
    const response = await axios.get(
      `counting/expenses?page=${page}&limit=${limit}&isDeleted=${isDeleted}&start_date=${start_date}&end_date=${end_date}&search=${search}`,
    )
    return response
  }

  async add({ expenses_name, money, date, note, study_year }) {
    axios.defaults.headers.common.Authorization = localStorage.getItem('accessToken')
    return axios
      .post(`/counting/expenses/add`, {
        expenses_name,
        money,
        date,
        note,
        study_year,
      })
      .then(Response => Response)
      .catch(error => {
        console.log('error', error)

        return error.response
      })
  }

  async edit({ expenses_name, money, date, note, id }) {
    axios.defaults.headers.common.Authorization = localStorage.getItem('accessToken')
    return axios
      .put(`/counting/expenses/edit`, {
        expenses_name,
        money,
        date,
        note,
        id,
      })
      .then(Response => Response)
      .catch(error => {
        console.log('error', error)

        return error.response
      })
  }

  async remove(id) {
    axios.defaults.headers.common.Authorization = localStorage.getItem('accessToken')
    return axios
      .delete(`/counting/expenses/delete/${id}`)
      .then(Response => Response)
      .catch(error => {
        console.log('error', error)

        return error.response
      })
  }
}

export default new ExpensesApi()
